var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.submitted)?_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"Success"}},[_c('b-button',{attrs:{"variant":"outline-success","to":{ name: 'demo-mio-tabella-test1'}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Ultimi utenti caricati ")],1)],1):_vm._e(),_c('b-card',{attrs:{"bg-variant":"Default","title":"Titolo Card"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome e Cognome","label-for":"nomeecognome"}},[_c('validation-provider',{attrs:{"name":"Nome e Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nome e Cognome"},model:{value:(_vm.campiform.nomeecognome),callback:function ($$v) {_vm.$set(_vm.campiform, "nomeecognome", $$v)},expression:"campiform.nomeecognome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.campiform.emailValue),callback:function ($$v) {_vm.$set(_vm.campiform, "emailValue", $$v)},expression:"campiform.emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Start data","label-for":"startdata"}},[_c('validation-provider',{attrs:{"name":"Start data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Datatime inizio lavoro"},model:{value:(_vm.campiform.startdata),callback:function ($$v) {_vm.$set(_vm.campiform, "startdata", $$v)},expression:"campiform.startdata"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Salario","label-for":"salario"}},[_c('validation-provider',{attrs:{"name":"Salario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"€","append":".00"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"100"},model:{value:(_vm.campiform.salario),callback:function ($$v) {_vm.$set(_vm.campiform, "salario", $$v)},expression:"campiform.salario"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status (mansione)","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors.length > 0 ? false:null},model:{value:(_vm.campiform.selected),callback:function ($$v) {_vm.$set(_vm.campiform, "selected", $$v)},expression:"campiform.selected"}})]}}])})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Salva ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }